<template>
  <b-overlay :show="showLoader">
    <template #overlay>
      <div class="text-center">
        <b-icon icon="stopwatch" font-scale="3" animation="cylon" />
        <p id="cancel-label">Creating Route. Please wait a moment.</p>
      </div>
    </template>
    <b-modal
      id="modal-primary"
      ref="route_modal"
      ok-only
      ok-title="Assign"
      modal-class="modal-primary"
      centered
      title="Assign Driver"
      no-close-on-backdrop
      @ok.prevent="handleSubmit"
    >
      <b-card-body>
        <ValidationObserver ref="routing">
          <b-form>
            <b-row>
              <!-- <b-col cols="12" lg="12">
                <b-form-group label="Outlet:">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="Outlet"
                    vid="Outlet"
                    rules="required"
                  >
                    <b-form-input v-model="form.remarks" />
                    <span
                      ><small class="text-danger">{{ errors[0] }}</small></span
                    >
                  </ValidationProvider>
                </b-form-group>
              </b-col> -->

              <b-col cols="12" lg="12">
                <ValidationProvider
                  #default="{ errors }"
                  vid="driver_id"
                  name="Driver"
                  rules="required"
                >
                  <b-form-group label="Assign Driver:">
                    <vSelect
                      v-model="form.driver_id"
                      :reduce="(option) => option.id"
                      :options="drivers"
                      placeholder="Select Driver"
                    >
                      <template v-slot:option="option">
                        <span class="mr-1"
                          ><b-avatar
                            :src="`${$s3URL}/minidc/driver_photo/${option.driver.driver_image}`"
                        /></span>
                        {{ option.label }}
                      </template>
                    </vSelect>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>
          </b-form>
        </ValidationObserver>
      </b-card-body>
    </b-modal>
    <b-row>
      <b-col cols="3">
        <b-card>
          <p>List of selected order/s</p>
          <div class="overflow-auto" style="max-height: 72vh">
            <draggable v-model="selectedOrders">
              <div
                v-for="(order, index) in selectedOrders"
                :key="index"
                class="mb-2"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <b-badge variant="warning" class="mr-1"
                    >Order: <strong>{{ index + 1 }}</strong></b-badge
                  >
                  <div @click="deleteOrder(index)" style="cursor: pointer">
                    <feather-icon icon="TrashIcon" size="16"></feather-icon>
                  </div>
                </div>
                <b-card bg-variant="light">
                  <div>
                    <b-badge variant="primary" class="mr-1">OrderID: </b-badge>
                    <strong>{{ order.id ? order.id : 'N/A' }}</strong>
                  </div>
                  <hr />
                  <div class="d-flex align-items-center">
                    <b-badge variant="secondary" class="mr-1"
                      >Address:
                    </b-badge>
                    <span
                      class="text-truncate d-inline-block"
                      style="max-width: 400px"
                      v-b-tooltip.hover
                      :title="order.address"
                    >
                      {{ order.address }}
                    </span>
                  </div>
                </b-card>
              </div>
            </draggable>
          </div>
        </b-card>
      </b-col>
      <b-col cols="9">
        <b-card>
          <b-row>
            <b-col>
              <div class="d-flex">
                <div class="mr-1">
                  <b-button
                    variant="warning"
                    @click="addMarker('start_order', 'yellow')"
                    >Starting Point</b-button
                  >
                </div>
                <div class="flex-grow-1">
                  <b-form-input
                    id="start-location-autocomplete"
                    v-model="start_order.address"
                    placeholder="Order start point"
                    class="font-small-3"
                    v-b-tooltip.hover="
                      start_order.address
                        ? start_order.address
                        : 'Click the starting point button and choose location in the map to add starting point'
                    "
                  />
                </div>
              </div>
            </b-col>
            <b-col>
              <div class="d-flex">
                <div class="mr-1">
                  <b-button
                    variant="danger"
                    @click="addMarker('end_order', 'red')"
                    >End Point</b-button
                  >
                </div>
                <div class="flex-grow-1">
                  <b-form-input
                    id="end-location-autocomplete"
                    v-model="end_order.address"
                    placeholder="Order end point"
                    class="font-small-3"
                    v-b-tooltip.hover="
                      end_order.address
                        ? end_order.address
                        : 'Click the end point button and choose location in the map to add ending point'
                    "
                  />
                </div>
              </div>
            </b-col>
            <b-col class="d-flex">
              <b-button @click="optimizeRoute">Optimize</b-button>
              <b-button
                variant="gradient-primary"
                @click.prevent="proceedRouting()"
                class="ml-auto"
              >
                <feather-icon icon="MapPinIcon" size="14" class="text-white" />
                <span class=""> Proceed </span>
              </b-button>
            </b-col>
          </b-row>
          <b-form-group label="">
            <b-form-checkbox
              v-for="client in clients"
              :key="client.id"
              v-model="selected_user"
              :value="client.id"
              name="flavour-4a"
              inline
            >
              {{ client.name }}
            </b-form-checkbox>
          </b-form-group>
          <div ref="map" style="height: 70.4vh" />
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import draggable from 'vuedraggable';
import { required } from '@validations';
import { BButton, BModal, VBModal } from 'bootstrap-vue';
import { Loader } from '@googlemaps/js-api-loader';
import OverlappingMarkerSpiderfier from 'overlapping-marker-spiderfier';
import { MAP_STYLING } from '@/common/Constants';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { success, error, info, requestError } from '@/common/SwalOptions';
import { getUserData } from '@/auth/utils';
export default {
  name: 'Map',
  components: {
    vSelect,
    BModal,
    BButton,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    draggable,
    flatPickr,
  },
  data() {
    return {
      userData: {},
      clients: [],
      showLoader: false,
      form: {
        remarks: null,
        driver_id: null,
        delivery_date: new Date().toISOString().split('T')[0],
        stops: [],
      },
      drivers: [],
      start_order: {},
      end_order: {},
      selected: [],
      selectedOrders: [],
      counter: 0,
      markers: [],
      order_data: [],
      oms: null,
      map: null,
      filter_date: new Date().toISOString().split('T')[0],
      filtered_date: null,
      selected_user: [],
      selected_order_type: 'Outlet',
      selected_status_type: 'All',
      selected_branch: '',
      svg: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
      defaultColor: '#ed3b4d',
      sc: '#63181f',
      drawingManager: null,
    };
  },
  mounted() {
    const loader = new Loader({
      apiKey: 'AIzaSyAuxm1Z531Z0hLTTIgbfjg_beIl1zmaX7M',
      version: 'weekly',
      libraries: ['drawing'],
    });

    loader.load().then(() => {
      this.userData = getUserData();
      this.selected_branch = this.userData.branch_id;
      this.displayMarker();
    });
    this.selectedOrders = JSON.parse(this.$route.query.orders);
    this.selected_user = this.$route.query.clients;
    this.filtered_date = this.$route.query.date;
    this.counter = Number(this.$route.query.counter);
  },
  watch: {
    selectedOrders() {
      this.updateMarkerLabels();
    },
  },
  methods: {
    // CONTINUE HERE!!!!
    initAutocomplete() {
      // Ensure the Google Maps API script is loaded with Places library
      const startInputField = document.getElementById("start-location-autocomplete");
      const endInputField = document.getElementById("end-location-autocomplete");
      const options = {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: true,
      }
      
      var autoCompleteStart = new google.maps.places.Autocomplete(startInputField, options);
      autoCompleteStart.bindTo("bounds", this.map);
      autoCompleteStart.addListener('place_changed', () => {
        const placeStart = autoCompleteStart.getPlace();
        this.placeMarker(placeStart.geometry.location, "red");
      });

      var autoCompleteEnd = new google.maps.places.Autocomplete(endInputField, options);
      autoCompleteEnd.bindTo("bounds", this.map);
      autoCompleteEnd.addListener('place_changed', () => {
        const placeEnd = autoCompleteEnd.getPlace();
        this.placeMarker(placeEnd.geometry.location, 'yellow');
      });
    },
    async proceedRouting() {
      this.getDrivers();
      this.$refs.route_modal.show();
    },
    async optimizeRoute() {
      if (!this.start_order || !this.end_order) {
        this.$bvToast.toast('Please add both start and end order markers', {
          title: 'Missing Information',
          variant: 'danger',
          solid: true,
        });
        return;
      }
      const payload = {
        start: {
          lat: this.start_order.lat,
          lng: this.start_order.lng,
          address: this.start_order.address,
          name: 'startpoint', // Update this if necessary
        },
        end: {
          lat: this.end_order.lat,
          lng: this.end_order.lng,
          address: this.end_order.address,
          name: 'endpoint', // Update this if necessary
        },
        locations: this.selectedOrders.map((order) => ({
          lat: order.lat,
          lng: order.lng,
          address: order.address,
          id: order.id,
        })),
      };

      try {
        const response = await this.$httpProcessing.post(
          'optimizerouteend',
          payload
        );
        // Handle response
        this.selectedOrders = response.data.data.slice(1, -1).map((order) => ({
          id: order.id,
          address: order.address,
          lat: order.lat,
          lng: order.lng,
        }));
      } catch (error) {
        // Handle error
        console.error(error);
      }
    },

    async deleteOrder(index) {
      const result = await this.$swal({
        title: 'Are you sure?',
        text: 'This order will not be part of the route.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger',
        },
      });
      if (result.isConfirmed) {
        // Get the order that will be deleted
        let order = this.selectedOrders[index];

        // Remove the order from the array
        this.selectedOrders.splice(index, 1);

        // Remove the marker from the map
        if (this.markers[order.id]) {
          this.markers[order.id].setMap(null);
          this.oms.removeMarker(this.markers[order.id]);
          delete this.markers[order.id];
        }

        // Update the URL
        this.$router.push({
          name: 'optimize-route',
          query: {
            orders: JSON.stringify(this.selectedOrders),
            clients: this.selected_user,
            date: this.filtered_date,
            counter: this.counter,
          },
        });
      }
    },

    updateMarkerLabels() {
      this.selectedOrders.forEach((order, index) => {
        if (this.markers[order.id]) {
          this.markers[order.id].setLabel({
            text: String(index + 1),
            color: 'white',
          });
        }
      });
    },

    async displayMarker() {
      // reset the counter and selected orders
      this.counter = 0;
      this.selected = [];
      this.filtered_date = this.filter_date;
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat: 2.731813, lng: 102.252502 },
        zoom: 8,
        styles: MAP_STYLING,
      });
      this.oms = new OverlappingMarkerSpiderfier(this.map, {
        markersWontMove: true,
        markersWontHide: true,
      });

      // Add start and end order markers
      this.addMarker('start_order', 'red');
      this.addMarker('end_order', 'yellow');

      try {
        this.markers = [];
        this.selectedOrders.forEach((data, index) => {
          this.order_data[data.id] = data;
          this.markers[data.id] = new google.maps.Marker({
            position: { lat: data.lat, lng: data.lng },
            label: {
              text: String(index + 1),
              color: 'white',
              fontSize: '16px',
            },
            icon: {
              path: this.svg,
              strokeColor: this.sc,
              strokeWeight: 0.5,
              strokeOpacity: 1,
              fillColor: 'blue',
              fillOpacity: 1,
              scale: 0.5,
              labelOrigin: new google.maps.Point(0, -30), // Adjust these values
            },
            clickable: false,
          });

          const info_window = new google.maps.InfoWindow({
            maxWidth: 200,
            disableAutoPan: true,
          });
          this.markers[data.id].addListener('mouseout', () => {
            info_window.close();
          });
          const self = this;
          this.markers[data.id].addListener('mouseover', () => {
            info_window.setContent(
              `<div class="card">
                <span style="color:#330000;">CLIENT : <strong>[${
                  data.user.name
                }]</strong></span>
                <span style="color:#330000;">ORDER : <b>${data.id} [${
                  data.order_type   
                }]</b></span>
                <span style="color:#330000;">NAME : <b>${
                  data.recipient.first_name
                }</b></span>
                <span style="color:#330000;">ADDRESS : <b>${
                  data.recipient.complete_address
                }</b></span>
                <span style="color:#330000;">REMARKS : <b>${
                  data.remarks ?? 'Not Set'
                }</b></span>
                <span style="color:#330000;">DELIVERY DATE : <b>${
                  data.delivery_date
                }</b></span>
              </div>`
            );
            info_window.open(self.map, self.markers[data.id]);
          });
          this.markers[data.id].setMap(this.map);
          this.oms.addMarker(this.markers[data.id]);
          const that = this;
        });
      } catch (error) {
        console.error(error);
      }
      this.updateMarkerLabels();
      this.initAutocomplete();
    },
    async placeMarker(event, color) {
      const lat = event.lat();
      const lng = event.lng();

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAuxm1Z531Z0hLTTIgbfjg_beIl1zmaX7M`
      );
      const data = await response.json();
      const address = data.results[0].formatted_address;

      // Create a new marker at the clicked location
      const marker = new google.maps.Marker({
        position: { lat, lng },
        map: this.map,
        icon: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
      });

      // Store the latitude, longitude, address, and marker in the order
      this[order] = { lat, lng, address, marker };
    },
    addMarker(order, color) {
      // If a marker already exists for the order, remove it
      if (this[order] && this[order].marker) {
        this[order].marker.setMap(null);
      }

      // If a listener already exists for the order, remove it
      if (this[order] && this[order].listener) {
        google.maps.event.removeListener(this[order].listener);
      }

      // Listen for the next click event on the map
      this[order].listener = google.maps.event.addListener(
        this.map,
        'click',
        (event) => {
          // Create a closure to capture the current values of color and order
          (async (color, order) => {
            // Get the latitude and longitude of the clicked location
            const lat = event.latLng.lat();
            const lng = event.latLng.lng();

            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyAuxm1Z531Z0hLTTIgbfjg_beIl1zmaX7M`
            );
            const data = await response.json();
            const address = data.results[0].formatted_address;

            // Create a new marker at the clicked location
            const marker = new google.maps.Marker({
              position: { lat, lng },
              map: this.map,
              icon: `http://maps.google.com/mapfiles/ms/icons/${color}-dot.png`,
            });

            // Store the latitude, longitude, address, and marker in the order
            this[order] = { lat, lng, address, marker };
          })(color, order);

          // Remove the listener
          google.maps.event.removeListener(this[order].listener);
        }
      );
    },

    async handleSubmit() {
      this.$refs.routing.validate().then((success) => {
        if (success) {
          this.$refs.route_modal.hide();
          this.form.stops = [];
          this.selectedOrders.forEach((index) => {
            this.form.stops.push({
              order_id: index.id,
              address: index.address,
              lat: index.lat,
              lng: index.lng,
            });
          });
          this.saveRoute();
        }
      });
    },

    async saveRoute() {
      if (this.selected_order_type != 'Singpaore') {
        this.form.delivery_date = this.filtered_date;
      }
      this.showLoader = true;
      const response = await this.$http.post('create_route', this.form);
      this.showLoader = false;
      if (response.data.status) {
        this.$swal(
          success({
            text: response.data.message,
          })
        ).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'route-tracker' });
          }
        });
      } else if (response.data.errors) {
        const { errors } = response.data;
        for (const a in errors) {
          const error = errors[a];
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error[0],
              icon: 'XIcon',
              variant: 'warning',
            },
          });
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'XIcon',
            variant: 'warning',
          },
        });
      }
    },

    async getDrivers() {
      this.drivers = [];
      const response = await this.$http.post('get_driver_by_branch');
      if (response.data.success === false) {
        this.$swal(requestError);
        this.isLoading = false;
        return;
      }

      this.drivers = response.data.drivers.map((items) => ({
        ...items,
        label: items.name,
      }));
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
